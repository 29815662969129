import '../sass/styles.scss'
import '@babel/polyfill'
import { typekit } from './lib/services'
import { navigation } from './lib/navigation'
import { modal } from './lib/modal'

typekit()

/* Navigation */
navigation(document.getElementById('js-menu-trigger'))

/* Initialize any Modals */
modal(
  document.querySelector('.modal'),
  document.querySelector('.modal-open')
)

/* Check for CSS Grid Support */
var html = document.getElementsByTagName('html')[0]

if (window.CSS && CSS.supports('display', 'grid')) {
  html.classList.remove('no-grid')
}
