
const modal = (el, trigger, cb) => {
  if (el) {
    trigger.addEventListener('click', e => {
      e.preventDefault()
      el.classList.remove('hidden')
      el.setAttribute('aria-hidden', 'false')
    })

    const close = el.querySelector('.modal-close')
    close.addEventListener('click', e => {
      e.preventDefault()
      el.classList.add('hidden')
      el.setAttribute('aria-hidden', 'true')
    })

    const action = el.querySelector('.modal-action')
    if (action) {
      action.addEventListener('click', e => {
        e.preventDefault()
        cb()
      })
    }
  }
}

export {
  modal
}
